.campaign {
  padding: 6.7% 12.1% 8.2%;
  color: $color-site-pink;
  background-color: #f4d0e0;
  @include mq() {
    padding-top: 13%;
    padding-right: 6.6%;
    padding-left: 6.6%;
    padding-bottom: 14%;
  }
  &__limited-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__limited {
    display: flex;
    align-items: center;
    justify-content: center;
    width: pxToRem(64);
    height: pxToRem(64);
    @include fontSizePxToRem(18.5);
    color: #f4d0e0;
    background-color: $color-gorgeous;
    border-radius: 50%;
  }
  &__logo {
    max-width: 33.7%;
    display: block;
    margin: 0 auto;
    margin-top: 2%;
    @include mq() {
      max-width: 63.7%;
      margin-top: 5%;
    }
  }
  &__title {
    margin-top: 3.6%;
    margin-bottom: 2.6%;
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(21);
    line-height: 1.5;
    @include mq() {
      margin-bottom: 8%;
    }
  }
  &__link-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4%;

    @include mq() {
      margin-top: 6.5%;
    }
  }
  &__link {
    display: block;
    width: 54%;
    padding-top: 2.1%;
    padding-bottom: 2.1%;
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(20);
    text-decoration: none;
    color: #ffffff;
    background: $color-gorgeous;
    text-align: center;
    border-radius: pxToRem(25);

    @include mq() {
      width: 100%;
      padding-top: 4%;
      padding-bottom: 4%;
    }
  }
  &__info {
    margin-top: 3.1%;
    text-align: center;
    @include mq() {
      margin-top: 4.8%;
    }
  }
  &__asterisk {
    margin-top: 1.2%;
    @include fontSizePxToRem(14);
    text-align: center;
  }
}