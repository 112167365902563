.history {
  padding-top: 6.2%;
  @include mq() {
    padding-top: 4.5%;
  }
  &__masssimo {
    position: relative;
    padding-right: 11.4%;
    padding-left: 8.9%;
    color: #ffffff;
    @include mq() {
      padding-right: 0;
      padding-left: 0;
      z-index: 5;
    }
  }
  &__masssimo-text {
    position: absolute;
    top: 21%;
    right: 10%;
    z-index: 5;
    text-align: right;
    @include mq() {
      top: 18%;
      right: 0;
      transform: translateX(-5%);
    }
  }
  &__masssimo-text-image {
    display: block;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 4%;
    max-width: 50%;
    @include mq() {
      max-width: 60.7%;
      margin-bottom: 1%;
    }
  }
  &__masssimo-title {
    margin-bottom: 2%;
    @extend .font-source-han-sans-japanese--heavy;
    @include fontSizePxToRem(38);
    @include mq() {
      letter-spacing: -1px;
    }
  }
  &__masssimo-info {
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(21);
    @include mq() {
      letter-spacing: -0.4px;
    }
  }
  &__masssimo-image {
    display: block;
    max-width: 61.6%;
    @include mq() {
      max-width: 73.3%;
    }
  }
}

.history__container {
  padding-right: 18.4%;
  padding-left: 18.4%;
  @include mq() {
    padding-right: 13.2%;
    padding-left: 13.2%;
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  images

// ----------------------------------------------------------------------------------------------------------------------------------------------

.number__max-width {
  max-width: 29.6%;
  @include mq() {
    max-width: 21.7%;
  }
}
.batch__max-width {
  @extend .batch__flex;
  max-width: pxToRem(101);
  @include mq() {
    max-width: pxToRem(84.5);
  }
}
.batch__flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.unhappy {
  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: pxToRem(94);
    height: pxToRem(94);
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(21.5);
    text-align: center;
    border-radius: 50%;
    color: $color-site-pink;
    background-color: $color-gorgeous;
    transform: translateY(-8%);
    @include mq() {
      width: pxToRem(78);
      height: pxToRem(78);
      @include fontSizePxToRem(18);
    }
  }
}

.unhappy {
  &__icon {
    height: 100%;
    max-height: pxToRem(27.5);
  }
}

.history {
  &__images {
    margin-top: 2%;
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  01

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__01 {
    position: relative;
    @extend .history__container;
    .number {
      @extend .number__max-width;
      position: absolute;
      top: 0;
      left: 0;
      @include mq() {
        top: -5%;
        left: 20%;
      }
    }
    .image {
      display: block;
      position: relative;
      z-index: 5;
      padding-top: 9%;
    }
    .unhappy {
      &__batch {
        @extend .batch__max-width;
        position: absolute;
        top: 16%;
        left: 9%;
        z-index: 10;
        @include mq() {
          top: 0;
          left: 2%;
        }
      }
      @extend .unhappy__text;
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  02

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__02 {
    position: relative;
    @extend .history__container;
    .number {
      @extend .number__max-width;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(9.5%);
      @include mq() {
        bottom: -48%;
        right: 24%;
        transform: none;
      }
    }
    .image {
      display: block;
      position: relative;
      margin-top: 5%;
      z-index: 5;
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  03

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__03 {
    position: relative;
    @extend .history__container;
    .number {
      @extend .number__max-width;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(38%);
      @include mq() {
        bottom: -42%;
        left: 21%;
        transform: none;
      }
    }
    .unhappy {
      &__batch {
        @extend .batch__max-width;
        position: absolute;
        top: 3%;
        right: 11%;
        z-index: 10;
        @include mq() {
          top: 13%;
          right: 6%;
        }
      }
      @extend .unhappy__text;
    }
    .image {
      display: block;
      position: relative;
      z-index: 5;
      padding-top: 12%;
      @include mq() {
        padding-top: 39%;
      }
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  04

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__04 {
    position: relative;
    @extend .history__container;
    .unhappy {
      &__batch {
        @extend .batch__max-width;
        position: absolute;
        top: 15%;
        left: 12.5%;
        z-index: 10;
        @include mq() {
          top: 13%;
          left: 7.5%;
        }
      }
      @extend .unhappy__text;
    }
    .image {
      display: block;
      position: relative;
      z-index: 5;
      padding-top: 27%;
      @include mq() {
        padding-top: 35%;
      }
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  05

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__05 {
    @extend .history__container;
    .image {
      display: block;
      position: relative;
      z-index: 5;
      margin-top: 3%;
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  06

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__06 {
    position: relative;
    @extend .history__container;
    .number {
      @extend .number__max-width;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-36%);
      @include mq() {
        top: -1%;
        right: 24%;
        transform: none;
      }
    }
    .unhappy {
      &__batch {
        @extend .batch__max-width;
        position: absolute;
        top: 10%;
        right: 12%;
        z-index: 10;
        @include mq() {
          top: 10%;
          right: 7%;
        }
      }
      @extend .unhappy__text;
    }
    .image {
      display: block;
      position: relative;
      z-index: 5;
      padding-top: 24%;
      @include mq() {
        padding-top: 35%;
      }
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  07

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__07 {
    position: relative;
    @extend .history__container;
    .number {
      @extend .number__max-width;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-39%);
      @include mq() {
        top: 3%;
        left: 19%;
        transform: none;
      }
    }
    .unhappy {
      &__batch {
        @extend .batch__max-width;
        position: absolute;
        top: 12%;
        left: 14%;
        z-index: 10;
        @include mq() {
          top: 6%;
          left: 6%;
        }
      }
      @extend .unhappy__text;
    }
    .image {
      display: block;
      position: relative;
      z-index: 5;
      padding-top: 26%;
      @include mq() {
        padding-top: 33%;
      }
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  08

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__08 {
    position: relative;
    @extend .history__container;
    margin-top: -5%;
    .image {
      display: block;
      position: relative;
      z-index: 3;
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  09

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__09 {
    position: relative;
    @extend .history__container;
    .number {
      @extend .number__max-width;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-27%);
      @include mq() {
        top: 12%;
        right: 24%;
        transform: none;
      }
    }
    .unhappy {
      &__batch {
        @extend .batch__max-width;
        position: absolute;
        top: 11%;
        right: 12%;
        z-index: 10;
        @include mq() {
          top: 18%;
          right: 6%;
        }
      }
      @extend .unhappy__text;
    }
    .image {
      display: block;
      position: relative;
      z-index: 5;
      padding-top: 28%;
      @include mq() {
        padding-top: 47%;
      }
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  10

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__10 {
    position: relative;
    @extend .history__container;
    .image {
      display: block;
      position: relative;
      z-index: 5;
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  11

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__11 {
    position: relative;
    @extend .history__container;
    .number {
      @extend .number__max-width;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-9%);
      @include mq() {
        top: 6%;
        left: 20%;
        transform: none;
      }
    }
    .unhappy {
      &__batch {
        @extend .batch__max-width;
        position: absolute;
        top: 12%;
        left: 13%;
        z-index: 10;
        @include mq() {
          top: 14%;
          left: 7%;
        }
      }
      @extend .unhappy__text;
    }
    .image {
      display: block;
      position: relative;
      z-index: 5;
      padding-top: 21%;
      @include mq() {
        padding-top: 34%;
      }
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  12

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__12 {
    position: relative;
    @extend .history__container;
    margin-top: 2%;
    @include mq() {
      margin-top: 5%;
    }
    .image {
      display: block;
      position: relative;
      z-index: 5;
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  13

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__13 {
    position: relative;
    @extend .history__container;
    margin-top: 3%;
    @include mq() {
      margin-top: 9%;
    }
    .image {
      display: block;
      position: relative;
      z-index: 1;
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  14

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__14 {
    position: relative;
    @extend .history__container;
    .number {
      @extend .number__max-width;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
      transform: translateY(-8%);
      @include mq() {
        top: 9%;
        right: 24%;
        transform: none;
      }
    }
    .unhappy {
      &__batch {
        @extend .batch__max-width;
        position: absolute;
        top: 14%;
        right: 13%;
        z-index: 10;
        @include mq() {
          top: 4%;
          right: 5%;
        }
      }
      @extend .unhappy__text;
    }
    .image {
      display: block;
      position: relative;
      z-index: 5;
      padding-top: 24%;
      @include mq() {
        padding-top: 35%;
      }
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  15

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__15 {
    margin-top: 4%;
    transform: translateX(8%);
    @include mq() {
      margin-top: 5%;
      transform: translateX(13.2%);
    }
    .image {
      display: block;
      max-width: 46%;
      @include mq() {
        max-width: 54%;
      }
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  16

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__16 {
    position: relative;
    z-index: 10;
    margin-top: 35%;
    @include mq() {
      margin-top: 49%;
    }
    .image {
      display: block;
      max-width: 57.4%;
      position: absolute;
      right: 10%;
      bottom: 0;
      @include mq() {
        max-width: 67%;
        right: 6.7%;
      }
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  17

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  &__17 {
    position: relative;
    padding-top: 4.5%;
    @include mq() {
      padding-top: 9.5%;
    }
    .image {
      display: block;
      padding-right: 4.2%;
      padding-left: 4.2%;
      @include mq() {
        padding-right: 6.7%;
        padding-left: 6.7%;
      }
    }
  }
  &__17-text {
    position: absolute;
    right: 0;
    bottom: 5%;
    display: flex;
    flex-direction: column;
  }
  &__17-title-wrapper {
    @include mq() {
      display: flex;
      justify-content: flex-end;
    }
  }
  &__17-title {
    margin-bottom: 3%;
    padding: 2%;
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(34);
    letter-spacing: pxToRem(-0.5);
    font-style: italic;
    color: $color-site-pink;
    background-color: $color-gorgeous;
    @include mq() {
      display: flex;
      align-items: flex-end;
      margin-bottom: 1%;
      padding: 1.5% 7% 1.5% 2%;
      @include fontSizePxToRem(20);
    }
  }
  &__17-tobe-image {
    max-width: 50%;
    display: block;
    margin-right: 8%;
    align-self: flex-end;
    @include mq() {
      max-width: 49%;
      margin-right: 7%;
    }
  }
}

// = == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
// ----------------------------------------------------------------------------------------------------------------------------------------------

//  /

// ----------------------------------------------------------------------------------------------------------------------------------------------

.history {
  picture[data-animation='fade-in'] {
    opacity: 1;
    will-change: transform, opacity;
    transition: transform 1000ms 0s ease, opacity 1000ms 0s ease;
    transform: translateY(0);
  }
  .image {
    &--non-visible {
      opacity: 0;
      will-change: transform, opacity;
      transform: translateY(pxToRem(20));
    }
  }
  [data-animation='slide-in'] {
    opacity: 1;
    will-change: transform, opacity;
    transition: transform 1000ms 0s ease, opacity 1000ms 0s ease;
    transform: translateX(0);
  }
  .slide {
    &--non-visible {
      opacity: 0;
      will-change: transform, opacity;
      transform: translateX(100%);
    }
  }
}