.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: pxToRem(6.5);
    padding-bottom: pxToRem(4.5);
    background-color: $color-site-pink;
  }
  &__date-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: pxToRem(51);
    height: pxToRem(51);
    margin-right: pxToRem(10.5);
    background-color: #fce3b9;
    border-radius: 50%;
  }
  &__date {
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(10);
    line-height: 1.15;
    color: $color-site-pink;
  }
  &__title-block {
    display: flex;
    flex-direction: column;
    color: #ffffff;
  }
  &__title {
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(20);
  }
}