.mail-magazine {
  padding-top: 8.8%;
  padding-bottom: 8.8%;
  color: #ffffff;
  background-color: #821548;
  @include mq() {
    padding-top: 12%;
    padding-bottom: 15.5%;
  }
  &__icon {
    display: block;
    max-width: 7.5%;
    margin: 0 auto;
    @include mq() {
      max-width: 15.5%;
    }
  }
  &__mail-text-image {
    display: block;
    max-width: 39.6%;
    margin: 0 auto;
    margin-top: 3.9%;
    @include mq() {
      max-width: 83.6%;
      margin-top: 7%;
    }
  }
  &__info {
    max-width: 40.8%;
    margin: 0 auto;
    @include mq() {
      max-width: 86.1%;
    }
  }
  &__title {
    margin-top: 8%;
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(21);
    text-align: center;
    @include mq() {
      margin-top: 11%;
    }
  }
  &__introduction {
    margin-top: 4%;
    margin-bottom: 6%;
    text-align: center;
    @include mq() {
      margin-top: 3%;
      margin-bottom: 9%;
    }
  }
  &__link {
    display: block;
    margin-top: 5.3%;
    padding-top: 4%;
    padding-bottom: 4%;
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(20);
    border: pxToRem(1) solid;
    border-radius: pxToRem(25);
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    @include mq() {
      margin-top: 5%;
    }
  }
  &__sns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 71.1%;
    margin: 0 auto;
    margin-top: 21.5%;
    @include mq() {
      margin-top: 25%;
    }
  }
}

.sns {
  &__link {
    display: block;
    max-width: 26.1%;
  }
}