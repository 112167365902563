.footer {
  padding-top: 2.8%;
  padding-bottom: 4.5%;
  color: #ffffff;
  background-color: #40192b;
  @include mq() {
    padding-top: 9%;
    padding-bottom: 11.5%;
  }
  &__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 77.7%;
    margin: 0 auto;
    margin-bottom: 2.4%;
    @include mq() {
      max-width: 90%;
      margin-bottom: 3.4%;
    }
    li {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      @include mq() {
        margin-bottom: 1.5%;
      }
    }
    li + li {
      border-left: 1px solid #ffffff;
    }
  }
  &__link {
    @include fontSizePxToRem(10);
    color: #ffffff;
    text-decoration: none;
  }
  &__copyright {
    margin-bottom: 0.6%;
    @include fontSizePxToRem(10);
    text-align: center;
  }
  &__text {
    @include fontSizePxToRem(10);
    text-align: center;
  }
}