.atf {
  position: relative;
  &__text-block {
    position: absolute;
    top: 0;
    left: 6.3%;
    @include mq() {
      left: 0;
    }
  }
  &__title-image {
    display: block;
    max-width: 50%;
    transform: translate(6%, 24%);
    opacity: 0;
    animation: show 1.0s ease 0.5s 1 forwards;
    @include mq() {
      max-width: 86%;
      transform: translate(2%, 6%);
    }
  }
  &__serialization-date {
    @extend .font-source-han-sans-japanese--medium;
    @include fontSizePxToRem(23.5);
    line-height: 1.51;
    letter-spacing: pxToRem(-1.0);
    color: #e40367;
    transform: translate(3.5%, 307%) skewY(-11deg);
    opacity: 0;
    animation: show 1.0s ease 1.0s 1 forwards;
    @include mq() {
      transform: translate(8%, 382%) skewY(-11deg);
    }
    @include mq(sm) {
      @include fontSizePxToRem(23);
      transform: translate(8%, 328%) skewY(-11deg);
    }
  }
  &__author {
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(29.8);
    letter-spacing: pxToRem(-1.0);
    color: #e40367;
    transform: translate(3.5%, 689%) skewY(-11deg);
    opacity: 0;
    animation: show 1.0s ease 1.5s 1 forwards;
    @include mq() {
      transform: translate(7%, 837%) skewY(-11deg);
    }
    @include mq(sm) {
      transform: translate(7%, 705%) skewY(-11deg);
    }
  }
}

@keyframes show {
  100% {
    opacity: 1;
  }
}