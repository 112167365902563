.font {
  // SourceHanSansJP
  &-source-han-sans-japanese {
    // Regular
    &--regular {
      font-family: "YakuHanJP", source-han-sans-japanese, sans-serif;
      font-style: normal;
      font-weight: 400;
    }

    // Medium
    &--medium {
      font-family: "YakuHanJP", source-han-sans-japanese, sans-serif;
      font-style: normal;
      font-weight: 500;
    }

    // Bold
    &--bold {
      font-family: "YakuHanJP", source-han-sans-japanese, sans-serif;
      font-style: normal;
      font-weight: 700;
    }

    // Heavy
    &--heavy {
      font-family: "YakuHanJP", source-han-sans-japanese, sans-serif;
      font-style: normal;
      font-weight: 900;
    }
  }

  // HiraKakuProN-W5


}