.container {
  display: flex;
  flex-direction: row;
}

$bg-width: calc((100% - 791px) / 2);
$bg-min-width: 178;

.bg {
  &__left {
    width: $bg-width;
    min-width: pxToRem($bg-min-width);
    height: 100vh;
    position: fixed;
    top: $header-height;
    left: 0;
    z-index: -1;
    background-image: url(../images/bg-left_x2.png);
    background-size: cover;
    background-repeat: repeat-y;
    @include mq() {
      visibility: hidden;
    }
  }
  &__right {
    width: $bg-width;
    min-width: pxToRem($bg-min-width);
    height: 100vh;
    position: fixed;
    top: $header-height;
    right: 0;
    z-index: -1;
    background-image: url(../images/bg-right_x2.png);
    background-size: cover;
    background-repeat: repeat-y;
    @include mq() {
      visibility: hidden;
    }
  }
}