.story {
  background-image: url(../images/story-topimg_pc.png);
  background-repeat: no-repeat;
  background-size: 44% auto;
  background-position: 9% -8%;
  @include mq() {
    background-size: 90% auto;
    background-position: -15% -6%;
  }
  &__img {
    display: block;
    max-width: 15.2%;
    margin: 0 auto;
    padding-top: 6.7%;
    padding-bottom: 2.5%;
    @include mq() {
      max-width: 32.1%;
      padding-top: 16.7%;
      padding-bottom: 4.5%;
    }
  }
  &__info-wrapper {
    padding-right: 4.2%;
    padding-left: 4.2%;
    @include mq() {
      padding-right: 3.3%;
      padding-left: 3.3%;
    }
  }
  &__info {
    padding-top: 5.5%;
    padding-bottom: 4%;
    border: pxToRem(1) solid #ffffff;
    overflow: hidden;
    @include mq() {
      padding-top: 6%;
      padding-bottom: 3%;
    }
  }
  &__title {
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(21);
    color: #ffffff;
    text-align: center;
    @include mq() {
      padding-left: 4.4%;
      padding-right: 4.4%;
      line-height: 1.5;
      letter-spacing: -1.3px;
      text-align: left;
    }
  }
  $tilt: 1.5deg;
  
  &__introduction-wrapper {
    margin-top: 3%;
    padding-top: 5.5%;
    padding-bottom: 6%;
    transform: rotate(-$tilt) scaleX(2);
    background-color: #f8deea;
    @include mq() {
      margin-top: 8%;
      transform: rotate(-3.5deg) scaleX(2);
    }
  }
  &__introduction {
    max-width: 69%;
    margin: 0 auto;
    line-height: 1.5;
    letter-spacing: pxToRem(0.3);
    color: $color-site-pink;
    text-align: justify;
    transform: rotate($tilt) scaleX(.5) skewY(-3deg);
    @include mq() {
      max-width: 100%;
      padding-right: 3.7%;
      padding-left: 3.7%;
      transform: rotate(2.5deg) scaleX(.5) skewY(-2deg);
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 101;
      width: 100%;
      height: 100%;
      background-image: url(../images/story-brackets_pc.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transform: scale(1.23, 1.2) skewY(3deg);
      @include mq() {
        background-image: url(../images/story-brackets_sp.png);
        transform: scale(0.94, 0.96) skewY(3deg);
      }
    }
  }
}