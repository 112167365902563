$header-height: pxToRem(62);

* {
  box-sizing: border-box;
}

body {
  position: relative;
  padding-top: $header-height;
  background-color: $color-site-pink;
  @extend .font-source-han-sans-japanese--regular;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.br {
  &--sp {
    display: none;
    @include mq() {
      display: block;
    }
  }
}

a {
  transition: all 300ms;
  &:hover {
    opacity: .6;
  }
}