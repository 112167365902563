.anniversary {
  position: relative;
  padding-top: 5.8%;
  padding-bottom: 6%;
  color: $color-site-pink;
  background-color: #ffffff;
  @include mq() {
    padding-top: 12%;
    padding-bottom: 9%;
  }
  &__30th-text {
    position: relative;
    display: block;
    max-width: 41.8%;
    margin: 0 auto;
    z-index:10;
    @include mq() {
      max-width: 88.3%;
    }
  }
  &__title {
    position: relative;
    z-index: 10;
    margin-top: 2%;
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(21);
    line-height: 1.5;
    text-align: center;
    color: #000000;
    @include mq() {
      max-width: 88.3%;
      margin-right: auto;
      margin-left: auto;
    }
  }
  &__link-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    margin-top: 33.5%;
    text-align: center;
    @include mq() {
      margin-top: 63%;
    }
  }
  &__link {
    width: 40%;
    display: block;
    padding-top: 2%;
    padding-bottom: 2%;
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(21);
    text-align: center;
    text-decoration: none;
    border-radius: pxToRem(25);
    color: #ffffff;
    background-color: $color-gorgeous;
    @include mq() {
      width: 85%;
      padding-top: 4%;
      padding-bottom: 4%;
    }
  }
  &__bg-image {
    position: absolute;
    bottom: 0;
    z-index: 3;
    transform: translateY(1%);
  }
}