.look-back {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7.5%;
  padding-bottom: 6%;
  @include mq() {
    flex-direction: column;
    padding-top: 5%;
    padding-bottom: 8%;
  }
  &__text {
    max-width: 34.1%;
    margin-right: 3.5%;
    line-height: 1.5;
    color: #ffffff;
    @include mq() {
      max-width: 83.3%;
      @include fontSizePxToRem(14);
      text-align: justify;
    }
  }
  &__link {
    display: block;
    width: 38%;
    padding-top: 1%;
    padding-bottom: 1.5%;
    border-radius: pxToRem(20);
    @extend .font-source-han-sans-japanese--bold;
    color: $color-site-pink;
    background-color: $color-gorgeous;
    text-align: center;
    text-decoration: none;
    @include mq() {
      width: 80.3%;
      margin-top: 3.5%;
      padding-top: 2.4%;
      padding-bottom: 2.8%;
    }
    &::after {
      content: '›';
      @include fontSizePxToRem(25);
      line-height: 0.64;
      font-weight: 500;
      margin-left: 2%;
      @include mq() {
        transform: rotateX(90deg);
      }
    }
  }
}