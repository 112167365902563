
.serialization-wrapper {
  padding-right: 4.2%;
  padding-bottom: 6.3%;
  padding-left: 4.2%;
  @include mq() {
    padding-right: 3.3%;
    padding-left: 3.3%;
    padding-bottom: 23.5%;
  }
}

.serialization {
  position: relative;
  padding-top: 4.4%;
  padding-bottom: 8.3%;
  padding-left: 5.3%;
  background-color: #f4d0e0;
  border-radius: pxToRem(15);
  @include mq() {
    padding-top: 8.4%;
    padding-right: 3.5%;
    padding-left: 3.5%;
    padding-bottom: 9.3%;
  }
  &__title-image {
    position: relative;
    z-index: 10;
    display: block;
    max-width: 56.7%;
    margin: 0 auto;
    @include mq() {
      max-width: 100%;
    }
  }
  $max-widht: 43.7%;
  &__sub-title {
    max-width: $max-widht;
    position: relative;
    z-index: 10;
    @extend .font-source-han-sans-japanese--bold;
    @include fontSizePxToRem(21);
    line-height: 1.5;
    letter-spacing: pxToRem(-1);
    text-align: justify;
    color: $color-site-pink;
    transform: translateY(19%);
    @include mq() {
      max-width: 100%;
    }
  }
  &__introduction {
    max-width: $max-widht;
    position: relative;
    z-index: 10;
    margin-top: 6%;
    line-height:1.5;
    text-align: justify;
    color: $color-site-pink;
    @include mq() {
      max-width: 100%;
      margin-top: 10%;
    }
  }
  &__info {
    max-width: $max-widht;
    position: relative;
    z-index: 10;
    margin-top: 5.5%;
    padding: 2% 2% 3.5%;
    background-color: rgba(229, 168, 23, 0.8);
    @include mq() {
      max-width: 100%;
      margin-top: 72%;
      padding: 5% 7% 5.5%;
      text-align: center;
    }
  }
  &__illust {
    max-width: 56.6%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 5;
    transform: translateY(0.5%);
    @include mq() {
      max-width: 100%;
    }
  }
}

.info {
  &__text {
    line-height: 1.5;
    color: #ffffff;
  }
  &__link-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__link {
    display: block;
    margin-top: 4.5%;
    padding-bottom: 3%;
    color: #ffffff;
    border-bottom: pxToRem(1) solid;
    text-decoration: none;
    @include mq() {
      margin-top: 2.5%;
    }
  }
}